import React from "react";
import { graphql } from "gatsby";
import Img from "gatsby-image"
import Layout from "../components/layout";
import SEO from "../components/seo";
import icon from "../images/icon-activities.svg";
import awareness from "../images/icon-awareness.svg";
import resident from "../images/icon-resident.svg";
import connect from "../images/icon-connect.svg";
import event from "../images/icon-event.svg";
import experiment from "../images/icon-experiment.svg";
import education from "../images/icon-education.svg";

import { documentToReactComponents } from '@contentful/rich-text-react-renderer';



const Program = ({ data }) => {
  const general = data.general.edges;
  const reoccurring = data.reoccurring.edges;
  return (
    <Layout>
      <SEO title="Lawtech Hub Program" />
      <div className="">
        <Img
        className="object-cover w-full h-64"
        fluid={data.file.childImageSharp.fixed}
        alt="Header image"
        />
      </div>
      <div className="container">
      <h1 className="title">Program</h1>
      </div>
      <div className="bg-white w-full border-t-2 border-b border-white">
        <div className="container flex py-6 my-2 self-center">
          <div className="w-auto lg:w-1/2 self-center">
            <h3 className="pt-6 text-center md:text-left">
              {documentToReactComponents(data.contentfulPageIntro.intro.json)}
            </h3>
          </div>
          <div className="w-1/2 hidden lg:block">
            <img src={icon} className="h-64 mb-0 float-right mx-12" alt="LawTech Hub Icons" />
          </div>
        </div>
      </div>


      <div className="container mt-12 mb-32">

        <div className="w-full">
          {general.map(({ node: post }) => (
              <div key={post.id} className="flex items-stretch mb-4">
                {post.type === "Resident" && <div className="self-stretch lg:w-12 lg:h-12 bg-purple-400"><img src={resident} className="m-0 hw-14 h-14 p-1" alt="Resident"/></div>}
                {post.type === "Connect" && <div className="self-stretch lg:w-12 lg:h-12 bg-teal-400"><img src={connect} className="m-0 md:w-14 md:h-14 p-1" alt="Connect"/></div>}
                {post.type === "Awareness" && <div className="self-stretch lg:w-12 lg:h-12 bg-blue-400"><img src={awareness} className="block m-0 w-14 h-14 p-1" alt="awareness"/></div>}
                {post.type === "Experiment" && <div className="self-stretch lg:w-12 lg:h-12 bg-orange-400"><img src={experiment} className="block m-0 w-14 h-14 p-1" alt="expirment"/></div>}
                {post.type === "Event" && <div className="self-stretch lg:w-12 lg:h-12 bg-orange-400"><img src={event} className="m-0 w-14 h-14 p-1" alt="Event"/></div>}
                {post.type === "Education" && <div className="self-stretch lg:w-12 lg:h-12 bg-purple-400"><img src={education} className="m-0 w-14 h-14 p-1" alt="Education"/></div>}
                {post.type === "" && <div className="lg:w-12 lg:h-12 bg-gray-400"></div>}
               
                <div className="flex items-stretch justify-between ml-1 bg-white flex-grow self-center uppercase tracking-wider font-bold text-xs sm:text-base py-3 pl-3">
                  <div>{post.item}</div>
                  <div className="self-center text-xs text-right font-serif text-gray-500 pl-4 pr-3">{post.displayDate}</div>
                </div>
              </div>

          ))}
        </div>



        <div className="w-full mt-24">
          <h2 className="mb-16">Regular Activities</h2>
          {reoccurring.map(({ node: post }) => (
              <div key={post.id} className="flex items-stretch mb-4">
                {post.type === "Resident" && <div className="self-stretch lg:w-12 lg:h-12 bg-purple-400"><img src={resident} className="m-0 hw-14 h-14 p-1" alt="Resident"/></div>}
                {post.type === "Connect" && <div className="self-stretch lg:w-12 lg:h-12 bg-teal-400"><img src={connect} className="m-0 md:w-14 md:h-14 p-1" alt="Connect"/></div>}
                {post.type === "Awareness" && <div className="self-stretch lg:w-12 lg:h-12 bg-blue-400"><img src={awareness} className="block m-0 w-14 h-14 p-1" alt="awareness"/></div>}
                {post.type === "Experiment" && <div className="self-stretch lg:w-12 lg:h-12 bg-purple-400"><img src={experiment} className="block m-0 w-14 h-14 p-1" alt="Experiment"/></div>}
                {post.type === "Event" && <div className="self-stretch lg:w-12 lg:h-12 bg-orange-400"><img src={event} className="m-0 w-14 h-14 p-1" alt="Event"/></div>}
                {post.type === "" && <div className="lg:w-12 lg:h-12 bg-gray-400"></div>}
               
                <div className="flex items-stretch justify-between ml-1 bg-white flex-grow self-center uppercase tracking-wider font-bold text-xs sm:text-base py-3 pl-3">
                  <div>{post.item}</div>
                  <div className="self-center text-xs text-right font-serif text-gray-500 pl-4 pr-3">{post.displayDate}</div>
                </div>
              </div>

          ))}
        </div>


      </div>
        
        

    </Layout>
  );
};
export default Program;



export const query = graphql`
  query programIndexPageQuery {
    contentfulPageIntro(page: { eq: "Program" }) {
      intro {
        json
      } 
    }
    general: allContentfulProgramItem(filter: { category: { eq: "General" } }, sort: {fields: [sortDate], order: [ASC]}) {
      edges {
        node {
          id
          item
          type
          sortDate
          displayDate
          category
        }
      }
    }
    
    reoccurring: allContentfulProgramItem(filter: { category: { eq: "Reoccurring" } }, sort: {fields: [sortDate], order: [ASC]}) {
      edges {
        node {
          id
          item
          type
          sortDate
          displayDate
          category
        }
      }
    }



    file(relativePath: { eq: "banner-program.jpg" }) {
      childImageSharp {
        fixed(width: 1200) {
          base64
          width
          height
          src
          srcSet
        }
      }
    }    
  }



`;